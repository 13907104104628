/* styles.css */
@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@200;300&display=swap");
@import url("https://use.typekit.net/ojy8bpt.css");
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@200;300;400&family=Inter:wght@100;200;300;400&family=Roboto+Condensed:wght@300;400;700&display=swap');
@import 'locomotive-scroll/dist/locomotive-scroll.css';

body {
  overflow-x: hidden;
  font-family: "Gothic A1", sans-serif;
  font-weight: 400;
}

.hero {
    /* background-color: #f2f2f2; */
    background: radial-gradient(#ffffff, #cacaca);
    min-height: 100vh;
    max-height: 100vh;
    padding-top:4vh;
  }
  
  /* .design {
    position: relative;
    background-color: #e6e6e6;
    min-height: 100vh;
    max-height: 100vh;
    padding-top:4vh;
  } */
  
  /* .illustration {
    background-color: #cccccc;
    min-height: 100vh;
    max-height: 100vh;
    max-height: 100vh;
    padding-top:4vh;
  }
  
  .motion-design {
    background-color: #b3b3b3;
    min-height: 100vh;
    max-height: 100vh;
    padding-top:4vh;
  } */
  
  .contact {
    background-color: #8f9eaa;
    min-height: 100vh;
    max-height: 100vh;
    padding-top:4vh;
  }
  
  /* Additional styles for other elements can go here */
/* Styling for the navigation bar */
.navigation {
  /* height: 40px; */
  /* display: flex; */
  /*justify-content: space-between; To separate the logo and menu links */
  /* align-items: center; */
  position: sticky;
  top: 0;
  z-index: 100;
  background-color:#ffffff;
}

/* Styling for the SVG logo */
.navigation .logo img {
  height: 30px; /* You can adjust as needed to fit within 40px */
  width: auto; /* Maintain aspect ratio */
}

/* Styling for the menu links */
.navigation .menu a {
  text-decoration: none; /* Remove underline */
  margin-right: 20px; /* Space between links */
}

/* Last link margin fix */
.navigation .menu a:last-child {
  margin-right: 0;
}

/* Styling for the navigation menu links */
.navigation .menu a,
.navigation .menu .work-together {
  text-transform: uppercase; /* Make text all caps */
  text-decoration: none; /* Remove underline */
  margin-right: 20px; /* Space between links */
  color: inherit; /* Use the same color as other text */
  font-size: 14px; /* Set the font size */
  letter-spacing: .5px; /* Add letter spacing */
}

/* Styling for the "Let's Work Together" button */
.navigation .menu .work-together {
  border: 2px solid #000; /* Outline with specified width and color */
  border-radius: 5px; /* Slightly rounded corners */
  background-color: transparent; /* Transparent background */
  padding: 5px 10px; /* Padding inside the button */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background-color transition */
}

/* Hover effect for the button */
.navigation .menu .work-together:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slightly shaded background on hover */
}

#motion-design button.work-together {
  margin-left: 5cqw;
  border: 2px solid #000;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 14px;
}

#motion-design .work-together:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Slightly shaded background on hover */
}

.navigation .menu {
  flex: 1;
  text-align: right;
}

.container {
  max-width: 1200px; /* or whatever width you choose */
  margin: 0 auto;
  padding: 0 20px; /* You can adjust padding as needed */
}
.container.navigation-container {
  max-width: 1280px; /* or whatever width you want */
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}


.logo {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.logo img {
  height: 30px; /* You can adjust as needed to fit within 40px */
  width: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between the image and the name */
}

.logo p {
  margin: 0; /* Remove default margin from the paragraph */
}

.logo .haradyn {
  font-weight: 300;
  color: #8c8d8f;
}


/* Example of a full-width element */
.full-width {
  width: 100%;
  max-width: none;
}

h1.logo-text {
  /* position:absolute;
  top:4vh;
  left:50%; */
  font-family: "Gothic A1", sans-serif;
  font-size: 6vh;
  font-weight: 200;
  color: #000;
}
h1.logo-text span.haradyn {
  font-weight: 300;
  color: #8c8d8f;
}
/* #three-container {
  width: 30vw;
  height: 100vh;
  position: relative;
}

#three-container canvas{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#logoBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 24vh;
  z-index: 10;
} */

/* Styling for the hero header wrapper */
.hero-header,.design-header, .illustration-header, .motion-design-header, .contact-header {
  container-type:inline-size;
  display: flex; /* Aligns the heading and button horizontally */
  align-items: center; /* Aligns items vertically */
  justify-content: space-between; /* Separates the heading and button */
}
/* Styling for the brand and button */
.brand {
  display: inline-flex; /* Aligns the text and button horizontally */
  align-items: center; /* Aligns items vertically */
}

/* Styling for the CTA button */
.cta-button {
  background-color: #3256d6; /* Background color */
  color: #fff; /* Text color */
  font-family: "Gothic A1", sans-serif; /* Font family */
  text-transform: uppercase; /* Makes text all caps */
  border: none; /* Removes the default border */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 1cqw 2cqw; /* Padding inside the button */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 2cqw; /* Font size */
  margin-left: 2cqw;
  letter-spacing: .5px; /* Letter spacing */
  transition: background-color 0.3s ease; /* Smooth transition effect */
  margin-top: -.7cqw;
  vertical-align: middle;
}

/* Hover effect for the CTA button */
.cta-button:hover {
  background-color: #2a4998; /* Changes color on hover */
}

h1.hero-head, h2.design-head, h2.illustration-head, h2.motion-design-head, h2.contact-head {
  margin-left: auto;
  margin-right: auto;
  font-family: "bennet-banner-extra-condense";
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 8cqw;
  margin-top: 0vw;
  margin-bottom: -3cqw;
  line-height: 9cqw;
}

h1.hero-head {
  text-align: center;
}

h2.design-head, h2.illustration-head, h2.motion-design-head, h2.contact-head {
  margin-left: 7.5vw;
}

.hero-content {
  display: flex;
}

#logoAnimation-container {
  width: 20vw;
}

.portfolio-images {
  container-type:inline-size;
  width: 80vw; /* remaining space */
  position: relative; /* for absolute positioning of child divs */
}

/* .image-placeholder {
  position: absolute;
} */

.image-0 {
  position: absolute;
  width: 16cqw;
  /* height: 13vw; */
  background-color: #f00;
  z-index: 12;
  top: 12cqw;
  left: 14cqw;
}

.image-1 {
  position: absolute;
  width: 33cqw;
  /* height: 16vw; */
  background-color: #0f0;
  z-index: 11;
  top: 30cqw;
  left: 24cqw;
}

.image-2 {
  position: absolute;
  width: 31cqw;
  /* height: 18vw; */
  background-color: #00f;
  z-index: 8;
  top: 9cqw;
  left: 26cqw;
}

.image-3 {
  position: absolute;
  width: 12cqw;
  /* height: 16vw; */
  background-color: rgb(16, 157, 160);
  z-index: 9;
  top: 12cqw;
  left: 66cqw;
}

.image-4 {
  position: absolute;
  width: 16cqw;
  /* height: 14vw; */
  background-color: rgb(71, 160, 16);
  z-index: 10;
  top: 14cqw;
  left: 51cqw;
}

.image-5 {
  position: absolute;
  width: 23cqw;
  /* height: 22vw; */
  background-color: rgb(160, 100, 16);
  z-index: 7;
  top: 18cqw;
  left: 30cqw;
}

.image-6 {
  position: absolute;
  width: 14cqw;
  /* height: 15vw; */
  background-color: rgb(160, 16, 148);
  z-index: 11;
  top: 36cqw;
  left: 29cqw;
}

.image-7 {
  position: absolute;
  width: 19cqw;
  /* height: 18vw; */
  background-color: rgb(86, 16, 160);
  z-index: 5;
  top: 10cqw;
  left: 77cqw;
}

.image-8 {
  position: absolute;
  width: 12cqw;
  /* height: 16vw; */
  background-color: rgb(86, 16, 160);
  z-index: 13;
  top: 26cqw;
  left: 60cqw;
}

.image-9 {
  position: absolute;
  width: 21cqw;
  /* height: 16vw; */
  background-color: #ff0;
  z-index: 4;
  top: 19cqw;
  left: 78cqw;
}

.image-10 {
  position: absolute;
  width: 14cqw;
  /* height: 19vw; */
  background-color: #f0f;
  z-index: 11;
  top: 35cqw;
  left: 70cqw;
}

.image-11 {
  position: absolute;
  width: 26cqw;
  /* height: 13vw; */
  background-color: #0ff;
  z-index: 1;
  top: 41cqw;
  left: 43cqw;
}



#three-container {
  container-type: inline-size;
  width: 20vw;
  height: 100vh;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
}

#canvas {
  /* display: flex; */
   /*flex-direction: column; Stack children vertically */
   /*align-items: center; Center children horizontally */
   width:70%;
   height:70%;
}


#logoBox {
  /* display: flex;
  flex-direction: column; */
   /*align-items: center; Center children horizontally */
  /*text-align: center;  Align text inside the children */
  position:absolute;
  top:10px;
  left:10px;
  z-index: 10;
}

h1.logo-text {
  font-family: "Gothic A1", sans-serif;
  font-size: 14cqw;
  font-weight: 200;
  color: #000;
}

.logosub,
.logopre {
  color: #000;
  font-family: "Gothic A1", sans-serif;
  text-transform: uppercase;
  text-align: center;
  text-indent: 1cqw;
}

.logopre {
    /* position:absolute;
    top:0;
    left: 0; */
  /* margin-top: -32vh;
  margin-bottom: 35vh; */
  letter-spacing: 7.55cqw;
    text-align: center;
    font-size: 3.9cqw;
}

.logosub {
  /* position:absolute;
  top:4vh;
  left:50%; */
  font-size: 4cqw;
    letter-spacing: 9.8cqw;
    margin-top: -6cqw;
}

canvas {
  margin-left: -56.5cqw;
  margin-top: -76cqw;
  margin-bottom: -80cqw;
}

.underline {
  display: block;
  width: 0;
  left:50%;
  height: 2px;
  background-color: #000; /* Adjust color as needed */
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease; /* Optional for smooth reversal */
}

.menu a {
  position: relative;
  display: inline-block;
}

.underline {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #000;
}

.link-text {
  position: relative;
}

.scroll-down {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 14px;
}

.arrow {
  width: 10px;
  height: 10px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(-45deg);
  margin: 5px auto;
}

/* .image-placeholder, .other-image-placeholder {
  opacity: 0;
} */

.image-section {
  position: relative; /* Contain absolutely positioned children */
}

/* General styling for all image placeholders */
.image-placeholder,
.other-image-placeholder {
  position: absolute;
  width: 100vw;
   /*left: 100vw; Positioned outside the viewport initially */
  top: 6vh;
  height: 60vh;
  background-color: #4a8582;
  z-index: 10;
  left: 0; /* Positioned inside the viewport initially */
  opacity: 1; /* Fully visible initially */
}

/* Added class for the placeholder wrapper */
.placeholders-wrapper {
  position: relative; /* Contain absolutely positioned children */
  height: 75vh; /* Adjust this height according to your layout */
}


/* Specific styling for the image placeholders */
.image-placeholder, .other-image-placeholder {
  background-color: #d8d4bd;
  container-type: inline-size;
  border-top-left-radius: 25px;
  display: flex;
  flex-direction: row;
  /* border: 1px solid #ddd; */
  padding: 3vw;
   /*border-radius: 15px 0 0 0; Example for one rounded corner */
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
}

.placeholder-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-right: 15px; /* Adjust this to control the space between the text and the image */
  max-width: 22cqw;
}

#motion-design .placeholder-text {
  max-width: 25cqw;
}

.placeholder-title {
  margin-left:5cqw;
  text-align: left;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 400;
   text-transform: uppercase;
}

.placeholder-content {
  
  display: flex;
  justify-content: space-between;
  align-items: top; /* This aligns the items vertically */
}

.placeholder-description {
 margin-left:5cqw; 
  /* flex: 1;
  padding-right: 20px; */
  font-size: 14px;
  line-height: 20px;
}

.placeholder-image {
  display:flex;
  flex:2;
  width: auto; /* Adjust the size as needed */
  height: 60cqh;
  max-height:60cqh;
  justify-content: left; /* Centers the image horizontally */
  /*border-radius: 10px;  Optional, for a rounded corner on the image */
}

.placeholder-image img {
  max-width: auto;
  height: 100%;
  /* border-radius: 10px; */
}

.contact-form {
  width: 100%;
  max-width: 70vw;
  margin: 5vw auto;
  padding: 20px;
  /* background: #f9f9f9; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  /* border-radius: 8px; */
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.input-row {
  display: flex;
  justify-content: space-between;
}

.input-group {
  flex: 48%;
  position: relative;
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  font-weight: 600;
  color: #555;
  margin-bottom: 6px;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-group textarea {
  min-height: 100px;
  resize: vertical;
}

.send-button {
  background-color: #3256d6;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  display: block;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #0056b3;
}


footer.footer {
  background-color: #000;
  height: 10vh;
  display: block;
  margin: 0;
  text-align: center;
  color: #fff;
  padding-top: 10px;
}

.lightbox-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's on top */
}

.video-container {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  font-weight: 700;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Adds shadow around the X */
  z-index: 100;
}


.video-container video {
  max-width: 100%;
  max-height: 100%;
}

/* New design code */

.design, .illustration {padding-top:0;}

.corp-design {
  background-color: #386170;
}
.ret-design {
  background-color: #B1802C;
}

.web-design {
  background-color: #005486;
}

.log-design {
  background-color: #C75A3D;
}

.pac-design {
  background-color: #6466AF;
}

.ill-pho {
  background-color: #386170;
}

.mot-design {
  background-color: #845683;
}

.corp-design .container, .ret-design .container, .web-design .container, .log-design .container, .pac-design .container, .ill-pho .container, .mot-design .container {
  /* background-color: #386170; */
  color: #fff;
  padding: 2rem 4rem;
  margin-top:0;
}

.corp-design .corp-wrap, .ret-design .ret-wrap, .web-design .web-wrap, .log-design .log-wrap, .pac-design .pac-wrap, .ill-pho .ill-wrap, .mot-design .mot-wrap{
  display: flex;
  flex-direction: row;
}

.corp-text, .web-text, .pac-text, .mot-text {
  max-width: 50cqw;
  padding-right: 6cqw;
}

 .ret-text, .log-text, .ill-text {
  max-width: 50cqw;
  padding-left: 6cqw;
}

.corp-design h2, .ret-design h2, .web-design h2, .log-design h2, .pac-design h2 , .ill-pho h2, .mot-design h2 {
  font-family: "bennet-banner-extra-condense";
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: clamp(2.5rem, 6vw, 5.2rem);
  margin-block-end: 0.4em;
  margin-block-start: 0;
}

.corp-text p, .ret-text p, .web-text p, .log-text p, .pac-text p, .ill-text p, .mot-text p {
  font-size: 14px;
  line-height: 25px;
  margin: 0 0 2rem 0;
}

.corp-images, .ret-images, .web-images, .log-images, .pac-images, .ill-images, .mot-images {
  display: grid;
  max-width:60vw;
  min-width:45vw;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, minmax(100px, auto));
  grid-auto-rows: minmax(100px,auto);
  grid-gap: 10px;
  margin-block-end: auto;
    margin-block-start: auto;
}



.corp-images .image1 {
  grid-column: 1/5;
  grid-row: 1/3;
}

.corp-images .image2 {
  grid-column: 5/13;
  grid-row: 1/1;
}

.corp-images .image3 {
  grid-column: 5/13;
}



.ret-images .image1 {
  grid-column: 1/7;
  grid-row: 1/2;
}

.ret-images .image2 {
  grid-column: 7/13;
  grid-row: 1/2;
}

.ret-images .image3 {
  grid-column: 1/13;
  grid-row: 2/3;
}



.web-images .image1 {
  grid-column: 1/6;
  grid-row: 1/2;
}
.web-images .image2 {
  grid-column: 6/13;
  grid-row: 1/3;
}

.web-images .image3 {
  grid-column: 1/6;
  grid-row: 2/3;
}



.log-images .image1 {
  grid-column: 1/6;
  grid-row: 1/2;
}

.log-images .image2 {
  grid-column: 6/13;
  grid-row: 1/2;
}

.log-images .image3 {
  grid-column: 1/6;
  grid-row: 2/3;
}

.log-images .image4 {
  grid-column: 6/9;
  grid-row: 2/3;
}

.log-images .image5 {
  grid-column: 9/13;
  grid-row: 2/3;
}



.pac-images .image1 {
  grid-column: 1/5;
  grid-row: 1/3;
}

.pac-images .image2 {
  grid-column: 5/13;
  grid-row: 1/2;
}

.pac-images .image3 {
  grid-column: 5/9;
  grid-row: 2/3;
}

.pac-images .image4 {
  grid-column: 9/13;
  grid-row: 2/3;
}



.ill-images .image1 {
  grid-column: 1/6;
  grid-row: 1/2;
}

.ill-images .image2 {
  grid-column: 1/6;
  grid-row: 2/3;
}

.ill-images .image3 {
  grid-column: 6/10;
  grid-row: 1/3;
}

.ill-images .image4 {
  grid-column: 10/13;
  grid-row: 1/2;
}
.ill-images .image5 {
  grid-column: 10/13;
  grid-row: 2/3;
}

.mot-images .image1 {
  grid-column: 1/7;
  grid-row: 1/3;
}

.mot-images .image2 {
  grid-column: 7/13;
  grid-row: 1/3;
}

.mot-images .image3 {
  grid-column: 1/13;
  grid-row: 3/4;
}



.image1, .image2, .image3, .image4, .image5 {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 10px 0 ;
}

.log-images .image1,.log-images .image2,.log-images .image3,.log-images .image4,.log-images .image5 {
  background-color: rgba(255, 255, 255, 0.95);
}

.image1 img, .image2 img, .image3 img, .image4 img, .image5 img {
  max-width:80%;
  /* height: max-content; */
  margin-top: auto;
  margin-bottom: auto;
  object-fit: contain;
}

.pac-images .image2 img {
  border-radius: 10px;
}

.cta-book {
  color:#fff;
  text-transform: uppercase; /* Make text all caps */
  text-decoration: none; /* Remove underline */
  font-size: 14px; /* Set the font size */
  letter-spacing: .5px; /* Add letter spacing */
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: transparent;
  padding: 6px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-book:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly shaded background on hover */
}

@media all and (max-width: 824px) {
  .corp-design .corp-wrap, .web-design .web-wrap, .pac-design .pac-wrap, .mot-design .mot-wrap {
    display: flex;
    flex-direction: column-reverse;
}

.image1 img, .image2 img, .image3 img, .image4 img, .image5 img {
  height: auto;
}

.ret-design .ret-wrap, .log-design .log-wrap, .ill-pho .ill-wrap  {
  display: flex;
  flex-direction: column;
}

.corp-design .container, .ret-design .container, .web-design .container,  .log-design .container,  .pac-design .container, .ill-pho .container , .mot-design .container {
  padding: 2rem 2rem;
}


.corp-text, .ret-text, .web-text, .log-text, .pac-text, .ill-text, .mot-text  {
  padding:0;
  max-width: 100%;
  margin-top: 3cqw;
}
.corp-images, .ret-images, .web-images, .log-images, .pac-images, .ill-images, .mot-images {
  max-width: 100%;
}

}


/* mobile portrait */
@media all and (max-width: 600px) {
  .logosub,
  .logopre {
    color: rgb(0, 0, 0);
    font-size: 1.7vh;
    font-family: "Gothic A1", sans-serif;
    text-transform: uppercase;
    letter-spacing: 4.7vw;
    text-align: center;
    text-indent: 0.3vw;
    margin-right: -4.7vw;
  }

  .logopre {
    margin-top: -58vw;
    margin-bottom: 68vw;
    letter-spacing: 4.7vw;
  }
}

.feedback-message {
  font-weight: bold;
  margin-top: 2rem;
}

/* Hamburger Menu Style */
.hamburger-menu {
  /* display: none; Initially hidden */
  /* flex-direction: column; */
  /* justify-content: space-around; */
  /* height: 24px; Adjust as needed */
  /* width: 25px; Adjust as needed */
  /* cursor: pointer; */

  position: relative;
			display: none;
			flex-direction: column;
			gap: 3.5px;
			width: 2.5rem;
			height: 2.5rem;
			align-items: center;
			justify-content: center;
}



.hamburger-menu span {
  /* display: block; */
  /* height: 2.5px; */
  /* background-color: black; Adjust as needed */
  /* margin: 2px 0; */
  /* border-radius: 2px; */

  width: 25px;
			height: 2.5px;
			border-radius: 2px;
			background-color: #000;
			transition: transform .2s ease-in-out, opacity .2s ease-in-out, ;
			transform-origin: center;
}

.hamburger-menu.open span:nth-child(1) {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}

.hamburger-menu.open span:nth-child(2) {
  transform: scaleX(0);
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}


/* Hide 'Let's Work Together' link on larger screens */
.navigation .menu .mobile-work-together {
  display: none;
}

/* Add line break in hero h1 after the first span */

h1.hero-head span:first-of-type::after {
 content: "\a";
  white-space: pre;
}

/* Hide 'Work Together' Button on Mobile */
@media all and (max-width: 600px) {

  .navigation .menu .mobile-work-together {
    display: block; /* Show this link only on mobile */
  }

  /* Hide 'Work Together' Button */
  .navigation .menu .work-together {
    display: none;
  }

  /* Show Hamburger Menu */
  .navigation .hamburger-menu {
    display: flex;
  }

  /* Initially Hide Regular Menu */
  .navigation .menu {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%;
    background-color: #fff;
    z-index: 101;
    flex-direction: column;
  }

  /* Show Menu When Open */
  .navigation .menu.open {
    display: flex;
    border-top: 1px solid #ccc;
  }

  /* Style for Menu Links */
  .navigation .menu a {
    display: block;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc; /* Optional */
  }

  /* Optional: Style for Dimming Background */
  .content-overlay {
    display: none;
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  .content-overlay.active {
    display: block;
  }

  #three-container {
    container-type: inline-size;
    width: 30vw;
    height: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .hero-content {
    display: flex;
    flex-direction: column;
}


#logoAnimation-container {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  margin: 10vw 0;
}

#canvas {
  width: 70%;
  height: 70%;
  margin-left: 150cqw;
}

.logosub, .logopre {
  color: rgb(0, 0, 0);
  font-size: 2vw!important;
  font-family: "Gothic A1", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2.5vw!important;
  text-align: center;
  text-indent: 0.3vw;
  margin-right: -4.7vw;
}

.logopre {
  margin:0;
  letter-spacing: 1.8vw!important;
}

/* add line breaks in hero head for smaller screens */
h1.hero-head span {
display: block;
}

/* adjust font for smaller screens */

h1.hero-head, h2.design-head, h2.illustration-head, h2.motion-design-head, h2.contact-head {
   font-size: 17cqw;
   line-height: 15cqw;
}

/* CTA bigger with more padding at phone size */
.cta-button {
  padding: 2cqw 3cqw;
  font-size: 5cqw;
}

#three-container svg {
  fill: currentColor;
  height: auto;
  max-width: 66vmin;
  transform-origin: center;
  width: 66%;
/*   animation-name: ckw;
   animation-duration: 35.5s;
  animation-iteration-count:infinite; */
  animation: ckw 30s linear infinite;
}

@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

text {
  font-size: 6px;
  letter-spacing: 7px;
}

.contact-header {
  flex-direction: column;
}


}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-left {
  animation: slideInFromLeft 0.9s ease forwards;
  opacity: 0;
}

.slide-in-right {
  animation: slideInFromRight 0.9s ease forwards;
  opacity: 0;
}

/* .fade-up{
  animation: 1s ease-out 0s 1 fadeInUp;
  opacity:0;
}

.fade-up.animate {
  opacity: 1; 
} 
*/

.fade-up {
  opacity: 0; /* Initially hidden */
  animation: fadeInUp 1s ease-out forwards; /* Increased duration, use 'forwards' to maintain the final state */
}

.portfolio-lightbox {
  /* Styles for the lightbox */
}

.portfolio-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:#ffffff;
  line-height: 1.5rem;
  padding: 0 4vw;
}

.portfolio-info {
  width: 35%; /* Adjust as needed */
  padding: 0 2vw;
  min-width:20vw;
  /* Additional styles for the info section */
}

.portfolio-image {
  width: 65%; /* Adjust as needed */
  /* Additional styles for the image section */
}

.portfolio-image img, .portfolio-image video {
  max-height:80vh;
  max-width:55vw;
}



.portfolio-navigation {
  /* Styles for navigation buttons */
}

.previous_btn {
  bottom: 20vh;
  left: 2vw;
  margin: auto;
  position: absolute;
  /* top: 0; */
}

.next_btn {
  bottom: 20vh;
  margin: auto;
  position: absolute;
  right: 2vw;
  /* top: 0; */
}

.previous_btn, .next_btn {
  cursor: pointer;
  height: 65px;
  opacity: 0.5;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  width: 65px;
}

.previous_btn:hover, .next_btn:hover {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .previous_btn {
    bottom: 5vh;
    left:5vw;
  }
  .next_btn {
    bottom: 5vh;
    right:5vw;
  }
  .lightbox-modal {
    padding-top: 5vh;
    align-items: flex-start;
}
.portfolio-content {
  flex-direction: column;
}
.portfolio-image {
  width: 100%;
  text-align: center;
}

.portfolio-image img, .portfolio-image video {
  max-height: 50vh;
  max-width: 85vw;
}

.portfolio-info {
  width: 80vw;
}

}



